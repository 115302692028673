import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAuditTrailDepreciation } from '../../../actions/AuditTrail/AuditTrailDepreciationApi';

const initialState = {
  error: false,
  isLoading: false,
  data: {},
};

export const getAuditTrailDepreciationAsync = createAsyncThunk('auditTrailDepreciation/fetchDepreciation', async (body) => {
  const response = await getAuditTrailDepreciation(body);
  return response;
});

export const AuditTrailDepreciationSlice = createSlice({
  name: 'Audit Trail Depreciation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuditTrailDepreciationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuditTrailDepreciationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getAuditTrailDepreciationAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectAuditTrailDepreciationLoading = (state) => state.auditTrailDepreciation.isLoading;

export const selectAuditTrailDepreciationData = (state) => state.auditTrailDepreciation.data;

export default AuditTrailDepreciationSlice.reducer;
