import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getPropertyListByIdSlice = createSlice({
  name: 'GetPropertyListById',
  initialState,
  reducers: {
    getPropertyListByIdRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getPropertyListByIdSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getPropertyListByIdFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    propertyRemoveDepreciationItem: (state, action) => {
      const currentData = JSON?.parse?.(JSON?.stringify?.(state?.data));
      const actionItems = currentData?.result?.action_item || [];
      const filteredItems = actionItems?.filter?.((item) => item?.id !== action?.payload);
      state.data.result.action_item = filteredItems;
    },
    clearPropertyData: (state) => {
      state.data = [];
    },
  },
});

export const { getPropertyListByIdSuccess, getPropertyListByIdFailure, getPropertyListByIdRequest, propertyRemoveDepreciationItem, clearPropertyData } = getPropertyListByIdSlice.actions;

export default getPropertyListByIdSlice.reducer;
