import React from 'react';
import { GetAbbreviation } from './index';
import { UserTypes } from '../components/common/userTypes';
import { Avatar } from 'antd';

const CommonAvatar = ({ size = null, profile = false, marginEnd = '', src = '', marginTop = '', text = '', shape = '', otherSrc = false, shadow = '' }) => {
  const { name, user_type, profile_image_path, company_logo } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};

  let imageUrl = otherSrc ? src : profile_image_path || company_logo;

  if (text) {
    imageUrl = null;
  }

  let initials = null;

  if (!imageUrl) {
    if (profile) {
      initials = <h3 className="text-center mt-2">{GetAbbreviation(text || name)}</h3>;
    } else {
      initials = <h6 className="text-center mt-2 pt-1">{GetAbbreviation(text || name)}</h6>;
    }
  }

  return (
    <Avatar
      size={size}
      className={`cursor-pointer text-center profileAvatar border-0 ${shadow}  ${marginEnd} ${marginTop} `}
      src={imageUrl}
      shape={shape || user_type === UserTypes.USER || user_type === UserTypes.SUB_TAXACCOUTANT || user_type === UserTypes.SUB_CHANNEL_PARTNER ? 'circle' : 'square'}
      style={{
        backgroundColor: '#56aaaa',
      }}
    >
      {!imageUrl && initials}
    </Avatar>
  );
};

export default CommonAvatar;
