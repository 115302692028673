import React, { useState, useEffect, useMemo } from 'react';
import { Button, Descriptions, notification, Popconfirm, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDepreciationAsync,
  getDepreciationListDataAsync,
  getViewDepreciationListDataAsync,
  selectDepreciationLoading,
  selectViewDepreciationListData,
} from '../../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { getDepreciationMethodLabel } from '../../../utils/ConstLabel';
import { getAuditTrailDepreciationAsync } from '../../../redux/reducers/slices/AuditTrail/AuditTrailDepreciationSlice';

const ViewDepreciation = ({ record = null, setDrawerOpen = () => {}, onUpdateDepreciation = () => {}, filters = null, category_id = null, fromAuditTrail = false, auditYear = null }) => {
  const dispatch = useDispatch();
  const viewData = useSelector(selectViewDepreciationListData);
  const [data, setData] = useState({ products: [], tableData: [] });

  const currentClient = localStorage.getItem('currentClient');

  const loading = useSelector(selectDepreciationLoading);

  useEffect(() => {
    dispatch(getViewDepreciationListDataAsync({ id: record?.id, currentClient }));
  }, [dispatch, record, currentClient]);

  useEffect(() => {
    if (viewData?.result) {
      // Create a map of all years across all products
      const allYears = new Set();
      viewData.result.forEach((product) => {
        product.depreciationValues.forEach((item) => {
          allYears.add(item.year);
        });
      });

      // Sort years and transform to financial year format
      const yearsArray = Array.from(allYears).sort((a, b) => a - b);
      const transformedData = yearsArray.map((year) => {
        const rowData = {
          key: year,
          year: `FY ${year}-${year + 1}`,
        };

        // Add data for each product
        viewData.result.forEach((product) => {
          const yearData = product.depreciationValues.find((item) => item.year === year);
          rowData[product.id] = yearData ? parseFloat(yearData.declineInValue).toFixed(2) : '-';
        });

        return rowData;
      });

      setData({
        products: viewData.result,
        tableData: transformedData,
      });
    }
  }, [viewData]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        fixed: 'left',
      },
      ...data.products.map((product) => ({
        title: (
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{product.product_name}</div>
          </div>
        ),
        dataIndex: product.id,
        key: product.id,
        align: 'right',
        render: (value) => <span>{value === '-' ? value : `$${Number(value).toFixed(2)}`}</span>,
      })),
    ];
  }, [data.products]);

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteDepreciationAsync({ id: record?.id, currentClient }));
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message || 'Depreciation deleted successfully!',
        });
        setDrawerOpen(false);
        onUpdateDepreciation();
        if (fromAuditTrail) {
          await dispatch(getAuditTrailDepreciationAsync({ ...filters, year: auditYear }));
        } else {
          await dispatch(getDepreciationListDataAsync({ ...filters, category_id }));
        }
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Descriptions
          className="transmeta"
          column={1}
          bordered={false}
          items={[
            {
              key: '1',
              label: 'Depreciation Method',
              children: getDepreciationMethodLabel(viewData?.result?.[0]?.depreciation_method),
            },
          ]}
        ></Descriptions>
        <Table columns={columns} dataSource={data.tableData} pagination={false} />
        <div className="d-flex justify-content-end align-items-center">
          <Popconfirm placement="topRight" title="Deleting this depreciation record will remove it from all financial reports. Proceed?" onConfirm={() => handleDelete()} okText="Yes" cancelText="No">
            <Button size="large" className="Delete_btn text-white mt-4">
              Delete Depreciation
            </Button>
          </Popconfirm>
        </div>
      </Spin>
    </div>
  );
};

export default ViewDepreciation;
