import { getRequest } from '../../../utils/apiUtils';
import { getPropertyListRequest, getPropertyListSuccess, getPropertyListFailure } from '../../reducers/slices/subscription/getSubscriptionPropertyList.slice';

export const GetSubscriptionPropertyList = (id = null, couponCode = '') => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/subscription/pending/properties?couponCode=${couponCode}`;
    } else {
      tempURl = `user/subscription/pending/properties?userId=${id}&couponCode=${couponCode}`;
    }
    await dispatch(getPropertyListRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListSuccess(result));
    }
    return await dispatch(getPropertyListFailure(result));
  };
};
