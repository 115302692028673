import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getNotificationDataSlice = createSlice({
  name: 'GetNotificationData',
  initialState,
  reducers: {
    getNotificationDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getNotificationDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getNotificationDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    removeDepreciationItem: (state, action) => {
      const currentData = JSON?.parse?.(JSON?.stringify?.(state?.data));
      const actionItems = currentData?.result?.ActionItemData || [];
      if (actionItems.length > 0) {
        const filteredItems = actionItems?.filter?.((item) => item?.id !== action?.payload);
        state.data.result.ActionItemData = filteredItems;
      }
    },
  },
});

export const { getNotificationDataSuccess, getNotificationDataFailure, getNotificationDataRequest, removeDepreciationItem } = getNotificationDataSlice.actions;

export default getNotificationDataSlice.reducer;
