import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: false };

export const getPropertyVideoSlice = createSlice({
  name: 'GetPropertyVideo',
  initialState,
  reducers: {
    PropertyVideoRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    PropertyVideoSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    PropertyVideoFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { PropertyVideoRequest, PropertyVideoSuccess, PropertyVideoFailure } = getPropertyVideoSlice.actions;

export const selectPropertyVideoLoading = (state) => state.propertyvideoReducer.isLoading;

export default getPropertyVideoSlice.reducer;
