import { CopyOutlined, FolderOpenOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Flex, Popover, notification, Row, Modal, Tag } from 'antd';
import dollorDashboard from '../../../assets/images/Transaction.svg';
import propertyDashboard from '../../../assets/images/property.svg';
import reportDashboard from '../../../assets/images/Report.svg';
import bankIcon from '../../../assets/images/bank.svg';
import { useNavigate } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useRef, useState } from 'react';
import './HowItWorks.css';

export default function HowWorks() {
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem('admin')) || {};

  // "Email Now" center modal
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const emailRef = useRef(null);

  // State to track which popover is open
  const [openPopover, setOpenPopover] = useState(null);

  // Refs for managing close timers
  const closeTimerRef = useRef(null);
  const HOVER_DELAY = 300; // ms

  // Function to clear any existing close timer
  const clearCloseTimer = () => {
    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current);
      closeTimerRef.current = null;
    }
  };

  // Function to start a close timer
  const startCloseTimer = () => {
    clearCloseTimer();
    closeTimerRef.current = setTimeout(() => {
      setOpenPopover(null);
    }, HOVER_DELAY);
  };

  const copyText = () => {
    notification.success({ message: 'Success', description: 'Copied.' });
  };

  const copyEmailText = () => {
    const plainText = emailRef.current.innerHTML.replace(/<br ?\/?>/g, '\n');
    navigator.clipboard.writeText(plainText);
    notification.success({ message: 'Success', description: 'Text copied.' });
  };

  const emailText = () => {
    const plainText = emailRef.current.innerHTML.replace(/<br ?\/?>/g, '\n');
    const mailtoLink = `mailto:${admin.virtual_email}?subject=Rent Summary&body=${encodeURIComponent(plainText)}`;
    window.location.href = mailtoLink;
  };

  // Trigger styles
  const triggerContainerStyle = {
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'background-color 0.3s',
    minWidth: 0,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const triggerContentStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  };

  // ------------------ Popover Content ------------------ //
  const addPropertyContent = (
    <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave} className="p-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          <h5 className="fw-bolder m-0">Add Property</h5>
        </span>
        <Tag color="red">One-time Setup</Tag>
      </div>
      <span>Update your property details:</span>
      <ul>
        <li>
          <b>Property Address</b>
        </li>
        <li>
          <b>Select Property Type</b>
        </li>
        <li>
          <b>Select Depreciation Method</b>
        </li>
        <li>
          <b>Bank Loan Details:</b> BSB and Account Number
        </li>
      </ul>
      <div className="pt-2">
        <Button onClick={() => navigate('/property/add', { state: { fromSameSite: true } })} type="primary">
          Add Property Now!
        </Button>
      </div>
    </div>
  );

  const sendDocumentContent = (
    <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave} className="p-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          <h5 className="fw-bolder m-0">Email Property-Related Documents</h5>
        </span>
        <Tag color="red" className="text-center">
          One-time Setup
        </Tag>
      </div>
      <span>
        Email your documents to your{' '}
        <strong>
          Unique ID : {admin.virtual_email}
          <CopyToClipboard text={admin.virtual_email} onCopy={copyText}>
            <CopyOutlined style={{ marginLeft: 4, cursor: 'pointer' }} />
          </CopyToClipboard>
        </strong>{' '}
        for automatic recording:
      </span>
      <ul>
        <li>
          <b>Depreciation Schedule</b>
        </li>
        <li>
          <b>Settlement Statement</b> received from your conveyancer at the time of purchase or sale
        </li>
        <li>
          <b>Rent Statements:</b>
          <ul>
            <li>End-of-Year Rent Summary for previous financial year</li>
            <li>
              Ask your property manager to send future rental summaries directly to your <br />
              <strong>
                Unique ID : {admin.virtual_email}
                <CopyToClipboard text={admin.virtual_email} onCopy={copyText}>
                  <CopyOutlined style={{ marginLeft: 4, cursor: 'pointer' }} />
                </CopyToClipboard>
              </strong>
            </li>
          </ul>
        </li>
      </ul>
      <div className="pt-2">
        <Button
          onClick={() => {
            setIsOpenEmail(true);
            handleTriggerMouseLeave();
          }}
          type="primary"
        >
          Email Now!
        </Button>
      </div>
    </div>
  );

  const connectBankContent = (
    <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave} className="p-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          <h5 className="fw-bolder m-0">Connect Bank Loan Account</h5>
        </span>
        <Tag color="red">One-time Setup</Tag>
      </div>
      <span>
        Connect your loan accounts from over 100 banks and lenders through secure Open Banking governed by the ACCC.
        <br />
        This ensures automatic recording of interest, bank fees, and other charges.
      </span>
      <ul>
        <li>
          <b>Only connect Property Loan Accounts.</b>
        </li>
        <li>
          <b>No need to connect credit cards, transaction accounts, or offset accounts—</b> this keeps your sensitive data private.
        </li>
        <li>
          <b>No online banking password needed.</b>
        </li>
      </ul>
      <div className="pt-2">
        <Button onClick={() => navigate('/bank', { state: { fromSameSite: true } })} type="primary">
          Connect Now!
        </Button>
      </div>
    </div>
  );

  const addExpenseContent = (
    <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave} className="p-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          <h5 className="fw-bolder m-0">Add Day-to-Day Expenses</h5>
        </span>
        <Tag color="processing">Ongoing</Tag>
      </div>
      <span>
        Update expenses directly paid by you (e.g., land tax, insurance, planning permits, capital improvements) using <br /> our mobile app or web portal in few seconds
      </span>
      <ul>
        <li>
          <b>Steps:</b>
          <ol>
            <li>
              <b>Scan or upload the invoice</b>
            </li>
            <li>
              <b>Select the property</b>
            </li>
            <li>
              <b>Select expense category</b>
            </li>
            <li>
              <b>Update amount</b>
            </li>
          </ol>
        </li>
      </ul>
      <div className="pt-2">
        <Button onClick={() => navigate('/record-expenses', { state: { fromSameSite: true } })} type="primary">
          Add Expense!
        </Button>
      </div>
    </div>
  );

  const reportContent = (
    <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave} className="p-2">
      <div className="d-flex justify-content-between flex-column mb-2">
        <span>
          <h5 className="fw-bolder m-0">Generate Reports</h5>
        </span>
        <p className="m-0 mt-1">Create reports and analyses in few clicks:</p>
      </div>
      <ul>
        <li>
          <b>Income &amp; Expense Reports:</b>
          <ul>
            <li>View your property's profit and loss for any period—year, month, or specific dates.</li>
          </ul>
        </li>
        <li>
          <b>Cost Base Reports:</b>
          <ul>
            <li>Know your actual property costs for Capital Gains Tax.</li>
          </ul>
        </li>
        <li>
          <b>Loan Summary Reports:</b>
          <ul>
            <li>View your loan accounts details across all banks in real time.</li>
          </ul>
        </li>
      </ul>
      <div className="pt-2">
        <Button onClick={() => navigate('/reports', { state: { fromSameSite: true } })} type="primary">
          Review Report!
        </Button>
      </div>
    </div>
  );

  // Define Popover items
  const popoverItems = [
    { key: 'addProperty', content: addPropertyContent, label: 'Add Property', icon: propertyDashboard },
    { key: 'sendDocument', content: sendDocumentContent, label: 'Send Property Documents', icon: FolderOpenOutlined },
    { key: 'connectBank', content: connectBankContent, label: 'Connect your Bank', icon: bankIcon },
    { key: 'addExpense', content: addExpenseContent, label: 'Add Day-to-Day Expenses', icon: dollorDashboard },
    { key: 'report', content: reportContent, label: 'Generate Reports', icon: reportDashboard },
  ];

  // Handlers for mouse enter and leave on triggers
  const handleTriggerMouseEnter = (popoverKey) => {
    clearCloseTimer();
    setOpenPopover(popoverKey);
  };

  const handleTriggerMouseLeave = () => {
    startCloseTimer();
  };

  // Handlers for mouse enter and leave on popover content
  function handlePopoverMouseEnter() {
    clearCloseTimer();
  }

  function handlePopoverMouseLeave() {
    startCloseTimer();
  }

  return (
    <>
      <Row className="mt-4 mb-3 pt-2 card_wrapper" gutter={20}>
        <Col span={24}>
          <h5 className="m-0 mb-3">How it works? Step by Step Process</h5>
          <div className="card">
            <div className="card-body">
              <Flex justify="space-between" align="center" wrap="nowrap" style={{ gap: '12px' }}>
                {popoverItems.map((item, index) => (
                  <Popover
                    key={item.key}
                    content={
                      <div onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave}>
                        {item.content}
                      </div>
                    }
                    trigger="hover"
                    overlayClassName="how-it-works-popover"
                    placement="bottom"
                    visible={openPopover === item.key}
                    onVisibleChange={(visible) => {
                      if (visible) {
                        setOpenPopover(item.key);
                      } else {
                        setOpenPopover(null);
                      }
                    }}
                    mouseEnterDelay={0.1}
                    mouseLeaveDelay={0.1}
                    overlayStyle={{ zIndex: 1060 }}
                  >
                    <div style={triggerContainerStyle} onMouseEnter={() => handleTriggerMouseEnter(item.key)} onMouseLeave={handleTriggerMouseLeave}>
                      <div style={triggerContentStyle}>
                        {index !== 0 && <RightOutlined style={{ color: '#dae0e5' }} />}
                        <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa' }} size="large">
                          {typeof item.icon === 'string' ? (
                            <img
                              style={{
                                filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)',
                              }}
                              width={20}
                              src={item.icon}
                              alt=""
                            />
                          ) : (
                            <item.icon style={{ fontSize: 20, color: '#56aaaa' }} />
                          )}
                        </Avatar>
                        <h6 className="m-0">{item.label}</h6>
                      </div>
                    </div>
                  </Popover>
                ))}
              </Flex>
            </div>
          </div>
        </Col>
      </Row>

      {/* Custom Mask */}
      {openPopover && <div className="mask"></div>}

      {/* EMAIL NOW MODAL (centered) */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={emailRef}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "<b>{admin.virtual_email}</b>"
          <br />
          <br />
          Property Details as below : <br />
          <b>[Your Property Name]</b>
          <br />
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day. <br />
          Regards
        </div>
        <div className="p-2 text-end">
          <Button onClick={copyEmailText} className="copy_btn3">
            Copy
          </Button>
          <Button onClick={emailText} className="copy_btn1">
            Send Mail
          </Button>
        </div>
      </Modal>
    </>
  );
}
