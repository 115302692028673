import { Line } from '@ant-design/plots';
import { Card, Col, Empty, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const LoanChart = ({ auditDataChart, auditDataChartLoading }) => {
  const [propertyConfigData, setPropertyConfigData] = useState([]);
  const [loanConfigData, setLoanConfigData] = useState([]);
  const [propertyFilterOption, setPropertyFilterOption] = useState([]);
  const [loanFilterOption, setLoanFilterOption] = useState([]);
  const [propertyFilterValue, setPropertyFilterValue] = useState([]);
  const [loanFilterValue, setLoanFilterValue] = useState([]);

  const handlePropertyFilterChange = (value) => {
    if (value.includes('all')) {
      if (propertyFilterValue.length === propertyFilterOption.length) {
        setPropertyFilterValue([]);
      } else {
        setPropertyFilterValue(propertyFilterOption.map((option) => option.value));
      }
    } else {
      setPropertyFilterValue(value);
    }
  };

  const handleLoanFilterChange = (value) => {
    if (value.includes('all')) {
      if (loanFilterValue.length === loanFilterOption.length) {
        setLoanFilterValue([]);
      } else {
        setLoanFilterValue(loanFilterOption.map((option) => option.value));
      }
    } else {
      setLoanFilterValue(value);
    }
  };

  useEffect(() => {
    if (auditDataChart) {
      setPropertyConfigData(auditDataChart?.interestAmountByProperty?.map((item) => ({ ...item, amount: Number(item.total_calculated_loan_balance) })));
      setLoanConfigData(auditDataChart?.interestAmountByAccountNumber?.map((item) => ({ ...item, created_at: new Date(item.created_at), amount: Number(item.total_calculated_loan_balance) })));

      setPropertyFilterOption(
        auditDataChart?.interestAmountByProperty
          ?.map((item) => {
            return {
              label: item.property_name,
              value: item.property_name,
            };
          })
          .filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index) || [],
      );
      setPropertyFilterValue([]);
      setLoanFilterValue([]);
      setLoanFilterOption(
        auditDataChart?.interestAmountByAccountNumber
          ?.map((item) => {
            return {
              label: item.account_number,
              value: item.account_number,
            };
          })
          .filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index) || [],
      );
    }
  }, [auditDataChart]);

  useEffect(() => {
    if (propertyFilterValue) {
      setPropertyConfigData(
        auditDataChart?.interestAmountByProperty
          ?.filter((item) => (propertyFilterValue.length > 0 ? propertyFilterValue.includes(item.property_name) : true))
          ?.map((item) => ({ ...item, amount: Number(item.total_calculated_loan_balance) })),
      );
    }
  }, [propertyFilterValue]);

  useEffect(() => {
    if (loanFilterValue) {
      setLoanConfigData(
        auditDataChart?.interestAmountByAccountNumber
          ?.filter((item) => (loanFilterValue.length > 0 ? loanFilterValue.includes(item.account_number) : true))
          ?.map((item) => ({ ...item, created_at: new Date(item.created_at), amount: Number(item.total_calculated_loan_balance) })),
      );
    }
  }, [loanFilterValue]);

  const config = {
    data: propertyConfigData || [],
    xField: 'postDate',
    yField: 'amount',
    colorField: 'property_name',
    axis: {
      y: {
        labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
      x: {
        type: 'time',
        tickInterval: 30,
        tickCount: 8,
        labelFormatter: (text) => dayjs(text).format('MMM-YY'),
      },
    },
    style: {
      lineWidth: 2,
    },
  };

  const bank_config = {
    data: loanConfigData || [],
    xField: 'created_at',
    yField: 'amount',
    colorField: 'account_number',
    axis: {
      y: {
        labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
      x: {
        type: 'time',
        tickInterval: 30,
        tickCount: 8,
        labelFormatter: (text) => dayjs(text).format('MMM-YY'),
      },
    },
    style: {
      lineWidth: 2,
    },
  };

  return (
    <Row gutter={0}>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <Card
          className="card"
          loading={auditDataChartLoading}
          title="By Property"
          extra={
            <div>
              <Select
                options={[{ value: 'all', label: '-- All Property --' }, ...propertyFilterOption]}
                onChange={handlePropertyFilterChange}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                mode="multiple"
                maxTagCount="responsive"
                placeholder="All Property"
                size="small"
                value={propertyFilterValue}
                allowClear
                style={{ width: 250 }}
              />
            </div>
          }
        >
          {propertyConfigData?.length > 0 ? <Line {...config} height={400} /> : <Empty />}
        </Card>
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <Card
          className="card"
          title="By BSB & Account"
          loading={auditDataChartLoading}
          extra={
            <div>
              <Select
                options={[{ value: 'all', label: '-- All Loan --' }, ...loanFilterOption]}
                onChange={handleLoanFilterChange}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                mode="multiple"
                maxTagCount="responsive"
                placeholder="All Loan"
                size="small"
                value={loanFilterValue}
                allowClear
                style={{ width: 250 }}
              />
            </div>
          }
        >
          {loanConfigData?.length > 0 ? <Line {...bank_config} height={400} /> : <Empty />}
        </Card>
      </Col>
    </Row>
  );
};

export default LoanChart;
