import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const getDepreciationProductList = async () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/user/transaction/products`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getDepreciationListData = async (obj) => {
  const { user_id } = obj;
  if (obj.user_id) {
    delete obj.user_id;
  }
  let tempUrl = ``;
  if (user_id) {
    tempUrl = `/user/property/depreciations?userId=${user_id}`;
  } else {
    tempUrl = `/user/property/depreciations`;
  }
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(tempUrl, obj);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getDepreciationData = async (id, type, user_id) => {
  let tempUrl = ``;

  if (user_id) {
    tempUrl = `/user/transaction/details/${id}?type=${type}&userId=${user_id}`;
  } else {
    tempUrl = `/user/transaction/details/${id}?type=${type}`;
  }

  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(tempUrl);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const saveDepreciationData = async (body) => {
  const { user_id } = body;
  delete body.user_id;

  let tempUrl = ``;

  if (user_id) {
    tempUrl = `/user/property/depreciation/add?userId=${user_id}`;
  } else {
    tempUrl = `/user/property/depreciation/add`;
  }

  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(tempUrl, body);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getViewDepreciationListData = async (id, currentClient) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/user/depreciation/${id}?userId=${currentClient}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const notApplicableDepreciation = async (id, currentClient) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/user/depreciation/not-applicable/${id}?userId=${currentClient}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteDepreciation = async (id, currentClient) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/user/property/depreciation/${id}?userId=${currentClient}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
