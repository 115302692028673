import { Button, DatePicker, Form, Input, Modal, notification, Select, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { PropertyType } from '../../../common/userTypes';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyTypeLabel } from '../../../../utils/ConstLabel';
import { GetPropertyListWithOutLoan } from '../../../../redux/actions/property/getPropertyListWithOutLoan.action';
import imgBrowse from '../../../../assets/browse.svg';
import { DocumentTypeDropDown } from '../../../../utils';
import { DeleteOutlined } from '@ant-design/icons';
import { STSToken } from '../../../../redux/actions/record_expense/uploadFile.action';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { selectUploadDocumentLoading, updateDocumentAsync, uploadDocumentAsync } from '../../../../redux/reducers/slices/DocumentUpload/DocumentUploadSlice';
import { GetdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documetation.action';
import dayjs from 'dayjs';
import { GetReciptdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';

const UploadDocument = ({
  isOpen = false,
  setIsOpen = () => {},
  propertyId = null,
  record = null,
  edit = false,
  setEdit = () => {},
  setRecord = () => {},
  individual = false,
  isReportPage = null,
}) => {
  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);
  const currentClient = localStorage.getItem('currentClient');
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [fileList, setFileList] = useState([]);

  const documentLoading = useSelector(selectUploadDocumentLoading);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onCancel = () => {
    setEdit(false);
    setIsOpen(false);
    form.resetFields();
    setRecord(null);
    setFileList([]);
  };

  useEffect(() => {
    if (isOpen && !propertyId && isReportPage === null) {
      dispatch(GetPropertyListWithOutLoan(currentClient));
    }
  }, [dispatch, currentClient, isOpen, propertyId]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.documentName,
        category_id: record.documentType,
        document_date: dayjs(record.date),
        property_id: record.propertyId || propertyId,
      });
      const uploadedFile = record.documentName;
      const formattedAttachments = uploadedFile
        ? [
            {
              uid: crypto.randomUUID(),
              name: record.documentName,
              status: 'done',
              thumbUrl: 'https://tpa-prod-assets-bucket.s3.ap-southeast-2.amazonaws.com/assets/document_logo.png',
              url: uploadedFile,
            },
          ]
        : [];
      setFileList(formattedAttachments);
    } else {
      form.resetFields();
      // No need to set document_date here; it will be set from initialValues
      setFileList([]);
    }
  }, [record, form]);

  useEffect(() => {
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((elm, i) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' ' + elm.street_name : '') : '',
          flag: elm.property_type === PropertyType.COMMERCIAL_PROPERTY ? 1 : 0,
          key: i,
          property_type: elm.property_type,
        };
      });
      setPropertyData(dt);
    }
  }, [propertyDta]);

  const uploadProps = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined />,
    },
  };

  const changeProperty = (e, data) => {
    form.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
  };

  const onRemove = () => {
    setFileList([]);
    if (edit) {
      setRecord((prevRecord) => ({ ...prevRecord, doc_url: null }));
    }
  };

  const beforeUpload = (file) => {
    if (!['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
      notification.error({
        message: 'Error',
        description: 'You can upload file formats .jpg, .jpeg, .png, .pdf only!',
      });
      return Upload.LIST_IGNORE;
    }
    const isLt2GB = file.size / 1024 / 1024 < 2048;
    if (!isLt2GB) {
      notification.error({
        message: 'Error',
        description: 'File must be smaller than 2GB!',
      });
      return Upload.LIST_IGNORE;
    }
    return false; // Prevent auto upload
  };

  const handlePreview = () => {
    setLoading(true);
    dispatch(GetReciptdocumentationdData(record.id, currentClient, 4)).then((res) => {
      if (res?.payload?.success) {
        setLoading(false);
        window.open(res?.payload?.result?.url, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const saveDocument = async (info) => {
    if (!info.property_id) {
      info.property_id = record?.propertyId || propertyId;
    }
    try {
      let res;
      if (edit) {
        res = await dispatch(updateDocumentAsync({ id: record.id, info }));
      } else {
        res = await dispatch(uploadDocumentAsync(info));
      }
      if (res?.payload?.data?.success) {
        setIsOpen(false);
        notification.success({
          message: 'Success',
          description: 'Document uploaded successfully!',
        });
        await dispatch(GetdocumentationdData(currentClient, propertyId));
        onCancel();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let sendData = values;
    sendData.document_date = dayjs(values.document_date).format('YYYY-MM-DD');
    delete sendData.receiptPath;

    if (edit && record?.doc_url && fileList.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Please upload file!',
      });
    }

    if (edit && record?.doc_url && fileList.length > 0 && !fileList[0].originFileObj) {
      // Use existing file URL
      sendData.doc_url = record?.doc_url;
      if (individual) {
        sendData.property_id = record?.property_id || propertyId;
      }
      setLoading(false);
      saveDocument(sendData);
    } else if (fileList && fileList.length > 0 && fileList[0].originFileObj) {
      const timestamp = new Date().valueOf();
      const fileName = fileList[0]?.name.replace(/[^a-zA-Z0-9.]/g, '');
      const key = `${admin1?.id}/documents/${timestamp}${fileName}`;

      dispatch(STSToken()).then(async (res) => {
        if (res.payload.success) {
          const awsConfig = {
            region: res?.payload?.result?.token.Region,
            credentials: {
              accessKeyId: res?.payload?.result?.token.AccessKeyId,
              secretAccessKey: res?.payload?.result?.token.SecretAccessKey,
              sessionToken: res?.payload?.result?.token.SessionToken,
            },
          };
          const client = new S3Client(awsConfig);
          const input = {
            Body: fileList[0]?.originFileObj,
            Bucket: res?.payload?.result?.token?.BucketName,
            Key: key,
            ContentType: fileList[0]?.type,
          };
          const command = new PutObjectCommand(input);
          const response = await client.send(command);
          if (response?.$metadata?.httpStatusCode === 200) {
            sendData.doc_url = `https://${res?.payload?.result?.token?.BucketName}.s3.amazonaws.com/${key}`;
            setLoading(false);
            if (individual) {
              sendData.property_id = propertyId;
            }
            saveDocument(sendData);
          } else {
            setLoading(false);
            notification.error({
              message: 'Failure',
              description: 'Something went wrong in file!',
            });
          }
        }
      });
    } else {
      notification.error({
        message: 'Failure',
        description: 'Please upload a document.',
      });
      setLoading(false);
    }
  };

  return (
    <Modal title={<h5>Document Upload</h5>} destroyOnClose footer={null} onCancel={onCancel} open={isOpen} centered width={750}>
      <Spin spinning={propertyDta.isLoading || documentLoading || loading}>
        <Form
          form={form}
          layout="vertical"
          className="mt-4"
          name="uploadDocument"
          onFinish={onFinish}
          initialValues={{
            document_date: dayjs(),
          }}
        >
          <Form.Item
            name={'name'}
            label="Document Name"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter document name',
              },
            ]}
          >
            <Input placeholder="Enter Document Name" />
          </Form.Item>
          <Form.Item
            name={'category_id'}
            label="Document Type"
            required
            rules={[
              {
                required: true,
                message: 'Please select document type',
              },
            ]}
          >
            <Select placeholder="Select Document Type" options={DocumentTypeDropDown(false)} />
          </Form.Item>

          {!propertyId && (
            <Form.Item
              name={'property_id'}
              label="Property"
              required
              rules={[
                {
                  required: true,
                  message: 'Please select property',
                },
              ]}
            >
              <Select
                style={{ width: '100%', textAlign: 'left' }}
                placeholder="Select Property"
                showSearch
                options={propertyData}
                onSelect={(e, data) => changeProperty(e, data)}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              />
            </Form.Item>
          )}
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: 'Please select document date',
              },
            ]}
            name="document_date"
            label="Document Date"
          >
            <DatePicker format="DD-MM-YYYY" style={{ width: '100%', background: 'transparent' }} placeholder="Document date" />
          </Form.Item>

          <Form.Item
            name="receiptPath"
            style={{ width: '100%', height: '100%' }}
            rules={[
              {
                validator: (_, value) => {
                  if (edit) {
                    if (fileList.length > 0 || record?.doc_url) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Please upload document'));
                    }
                  } else {
                    if (fileList.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Please upload document'));
                    }
                  }
                },
              },
            ]}
          >
            <Upload.Dragger
              listType="picture"
              onPreview={handlePreview}
              onRemove={onRemove}
              {...uploadProps}
              maxCount={1}
              customRequest={dummyRequest}
              onChange={handleChange}
              beforeUpload={beforeUpload}
              fileList={fileList}
              style={{ width: '100%', height: '100%' }}
              className="file-upload-custom"
              accept=".jpg, .jpeg, .png, image/jpeg, image/png, .pdf"
            >
              <p className="ant-upload-drag-icon">
                <img src={imgBrowse} alt="browse" />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload (.jpg, .jpeg, .png, .pdf )</p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item className="d-flex justify-content-center">
            <Button htmlType="submit" type="primary">
              {edit ? 'Update' : 'Upload'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UploadDocument;
