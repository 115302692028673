import React from 'react';
import { Drawer } from 'antd';
import ViewDepreciation from './ViewDepreciation';

const DrawerViewDepreciation = ({
  drawerOpen = false,
  setDrawerOpen = () => {},
  record = null,
  onUpdateDepreciation = () => {},
  filters = null,
  category_id = null,
  fromAuditTrail = false,
  auditYear = null,
}) => {
  const onClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Drawer destroyOnClose title={`Depreciation Items`} placement="right" maskClosable={false} width={1120} onClose={onClose} open={drawerOpen}>
      <ViewDepreciation
        record={record}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        onUpdateDepreciation={onUpdateDepreciation}
        fromAuditTrail={fromAuditTrail}
        filters={filters}
        category_id={category_id}
        auditYear={auditYear}
      />
    </Drawer>
  );
};

export default DrawerViewDepreciation;
