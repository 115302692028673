// EmailPreviewModal.js
import React from 'react';
import { Button, Modal, notification } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const EmailPreviewModal = ({ visible, onClose, category_list, auditYear, recipientName, yourNameOrCompany }) => {
  const formatAmount = (amount) => `$${Number(amount || 0).toFixed(2)}`;
  const categoryDta = useSelector((state) => state.getCategoryListReducer);

  const ref = React.useRef(null);

  const getCategoryName = (id) => {
    if (categoryDta.data.result) {
      const dt = categoryDta.data.result.find((e) => e.id === id);
      return dt.category_name || '';
    }
  };

  const copyText = () => {
    // Clone the styled content
    const emailContent = ref.current;
    const tempElement = document.createElement('div');

    // Apply the innerHTML from the email content
    tempElement.innerHTML = emailContent.innerHTML;

    // Style the temporary element to ensure styles are preserved
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px'; // Hide the element off-screen
    tempElement.style.whiteSpace = 'pre'; // Preserve formatting

    // Append to body
    document.body.appendChild(tempElement);

    // Select the content
    const range = document.createRange();
    range.selectNodeContents(tempElement);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      // Copy to clipboard
      const success = document.execCommand('copy');
      if (success) {
        notification.success({
          message: 'Success',
          description: 'Text copied.',
        });
      } else {
        notification.error({
          message: 'Failure',
          description: 'Failed to copy content',
        });
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    // Cleanup
    document.body.removeChild(tempElement);
    selection.removeAllRanges();
  };

  // Generate the email content
  const generateContent = () => {
    if (category_list.length === 0) {
      return <p>No discrepancies found for the current financial year.</p>;
    }

    return category_list.map((property, index) => (
      <div key={property.property_id} style={{ marginBottom: '20px' }}>
        <h6>Property Address: {property.property_name}</h6>
        <ul>
          {property.category.map((cat) => {
            const isRed = Number(cat.py_amount) > Number(cat.cy_amount) || Number(cat.cy_amount) === 0;
            if (isRed) {
              return (
                <li key={cat.category_id}>
                  <strong>{getCategoryName(cat.category_id)}: </strong>
                  {Number(cat.cy_amount) === 0 ? (
                    <>
                      {getCategoryName(cat.category_id)} {` `}
                      cannot be zero for this financial year.
                    </>
                  ) : (
                    <>
                      Last Year: {formatAmount(cat.py_amount)} | Current Year: {formatAmount(cat.cy_amount)}
                    </>
                  )}
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    ));
  };

  return (
    <Modal
      title={<h4>{`Property Expense Review for ${`FY ${auditYear.toString().slice(-2)}-${(Number(auditYear) + 1).toString().slice(-2)}`}`}</h4>}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <div className="email-preview-modal" ref={ref}>
        <p>Hi {recipientName || 'Recipient'},</p>
        <p>
          After reviewing your property expenses for the {`financial year ${auditYear.toString().slice(-2)}-${(Number(auditYear) + 1).toString().slice(-2)}`}, we noticed that some of your expenses are
          either missing or lower than expected compared to last year.
        </p>
        <p>For each property, please see the details below:</p>
        {generateContent()}
        <h5>Important Points To Consider:</h5>
        <ul>
          <li>
            <strong>Land Tax:</strong> Typically remains stable or increases year-over-year.
          </li>
          <li>
            <strong>Council Rates:</strong> Lower rates may indicate missed payments or unrecorded changes.
          </li>
          <li>
            <strong>Repairs and Maintenance:</strong> Significant changes may indicate incomplete data or unreported expenses.
          </li>
          <li>
            <strong>Insurance:</strong> Insurance should be consistent and reflect ongoing coverage for the property.
          </li>
        </ul>
        <p>
          Please review these items and update your records as needed. Once the updates are complete, kindly share the revised information with your tax accountant to ensure accurate reporting and
          compliance.
        </p>
        <p>If you have any questions or need assistance with this process, feel free to reach out.</p>
        <p>
          Best regards,
          <br />
          {yourNameOrCompany || ''}
        </p>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyText()}>
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EmailPreviewModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  category_list: PropTypes.array.isRequired,
  auditYear: PropTypes.string.isRequired,
  recipientName: PropTypes.string,
  yourNameOrCompany: PropTypes.string,
};

export default EmailPreviewModal;
