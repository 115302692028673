import { getRequest } from '../../../utils/apiUtils';

export const getAuditTrailDepreciation = (body) => {
  const { skip, limit, userId, year } = body;

  let tempUrl;
  if (userId) {
    tempUrl = `user/audit-trail/depreciations?skip=${skip}&limit=${limit}&year=${year}&userId=${userId}`;
  } else {
    tempUrl = `user/audit-trail/depreciations?skip=${skip}&limit=${limit}&year=${year}`;
  }

  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(tempUrl);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
