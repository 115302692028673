import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Col, DatePicker, Empty, Form, Row, Select, Spin, Timeline } from 'antd';
import dayjs from 'dayjs';
import '../../../assets/less/index.less';

import { useDispatch, useSelector } from 'react-redux';
import { GetUserActivity, GetMoreUserActivity } from '../../../redux/actions/user-activity/GetUserActivity.action';
import { GetPropertyListWithOutLoan } from '../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { userActivityTypesOptions, userSourcesOptions, activityIcon } from './activity-options';
import { UserTypes } from '../../common/userTypes';
import './index.less';

export default function UserActivity() {
  const [filterForm] = Form.useForm();
  // Filter Context
  const [filters, setFilters] = useState({
    skip: 0,
    limit: 20,
    property_id: '',
    user_type: '',
    activity_type: '',
    startDate: '',
    endDate: '',
  });

  const dispatch = useDispatch();
  const [activityUserData, setActivityUserData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const loadMoreRef = useRef();
  const [hasInitialLoadCompleted, setHasInitialLoadCompleted] = useState(false); // Add this state

  const [loading, setLoading] = useState(false);
  const activityData = useSelector((state) => state.getUserActivityReducer);
  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);

  useEffect(() => {
    dispatch(GetPropertyListWithOutLoan());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetUserActivity(filters));
  }, [filters, dispatch]);

  useEffect(() => {
    filterForm.setFieldsValue({
      Property_type: filters?.property_id === '' ? 'all' : filters?.property_id,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      user_type: filters?.user_type === '' ? 'all' : filters?.user_type,
      activity_type: filters?.activity_type === '' ? 'all' : filters?.activity_type,
    });
  }, [filters]);

  const loadMoreData = useCallback(
    (skipValue) => {
      dispatch(GetMoreUserActivity({ ...filters, skip: skipValue || 0 }));
    },
    [filters],
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasInitialLoadCompleted && activityData.total > activityUserData.length) {
        loadMoreData(activityUserData.length);
      }
    },
    [hasInitialLoadCompleted, activityData.total, activityUserData.length],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [handleObserver]);

  const startDateChange = (value) => {
    setFilters({ ...filters, startDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    setFilters({ ...filters, endDate: value && value !== null ? value.format('YYYY-MM-DD') : '' });
  };

  const propertyChange = (value) => {
    if (value === 'all') {
      return setFilters({ ...filters, property_id: '' });
    } else {
      return setFilters({ ...filters, property_id: value || '' });
    }
  };

  const sourceChange = (value) => {
    if (value === 'all') {
      return setFilters({ ...filters, user_type: '' });
    } else {
      return setFilters({ ...filters, user_type: value || '' });
    }
  };

  const activityChange = (value) => {
    if (value === 'all') {
      return setFilters({ ...filters, activity_type: '' });
    } else {
      return setFilters({ ...filters, activity_type: value || '' });
    }
  };

  useEffect(() => {
    setLoading(activityData.isLoading);

    if (activityData && activityData.data?.result?.data && activityData.error === false) {
      // remove duplicate records using unique key id
      const dt = activityData.data.result?.data
        ?.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
        ?.map((elm) => {
          return { ...elm, expense_date: dayjs(elm.expense_date).format('DD-MM-YYYY'), key: crypto.randomUUID() };
        });
      setActivityUserData(dt);
      setHasInitialLoadCompleted(true); // Set this to true once initial data load completes
    }
  }, [activityData]);

  useEffect(() => {
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((e) => {
        return {
          value: e.id,
          label: e.street_number ? e.street_number + (e.suburb ? ' - ' + e.street_name : '') : '',
        };
      });
      setPropertyData(dt);
    }
  }, [propertyDta]);

  const timelineItems =
    activityUserData &&
    activityUserData.map((item) => ({
      label: (
        <>
          <span className="date">{dayjs(item.created_at).format('DD/MM/YYYY')}</span>
          <br />
          <span className="time"> {dayjs(item.created_at).format('hh:mm A')}</span>
        </>
      ),
      dot: activityIcon(item.activity_type),
      children: (
        <>
          <span className="title">{item.activity_tital}</span>
          <br />
          <span className="action-by" style={{ textTransform: 'capitalize' }}>
            By {item?.user_type === UserTypes.ADMIN ? 'Automated Entry' : item.name}
          </span>
        </>
      ),
    }));

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <Row className="align-items-center mt-1">
            <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={6} span={4}>
              <div className="text-center text-sm-start mb-sm-0  mb-sm-0">
                <h5 className="m-0">Activity</h5>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 mb-3 card_wrapper" gutter={20}>
            <Col xl={7} lg={7} md={12} sm={24} xs={24}>
              <Card className="box_shadow">
                <Form form={filterForm} layout="vertical">
                  <Form.Item name="startDate" valuePropName={'startDate'} label="Start Date">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={startDateChange}
                      style={{ width: '100%', background: 'transparent' }}
                      placeholder="Start Date"
                      disabledDate={(date) => {
                        return date && filterForm.getFieldValue(['endDate']) && date > dayjs(filterForm.getFieldValue(['endDate']));
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="endDate" valuePropName={'endDate'} label="End Date">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={endDateChange}
                      style={{ width: '100%', background: 'transparent' }}
                      placeholder="End Date"
                      disabledDate={(date) => {
                        return date && filterForm.getFieldValue(['startDate']) && date < dayjs(filterForm.getFieldValue(['startDate']));
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="Property_type" label="Select Property">
                    <Select
                      style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                      placeholder="Select Property"
                      className="transSelect"
                      id="activity_property_type"
                      onChange={propertyChange}
                      showSearch
                      options={[
                        {
                          label: 'All',
                          options: [
                            {
                              label: ' - - All Property - - ',
                              value: 'all',
                            },
                          ],
                        },
                        {
                          label: 'Properties',
                          options: propertyData,
                        },
                      ]}
                      optionFilterProp="children"
                      dropdownStyle={{
                        color: 'rgba(0, 0, 0, 0.45)',
                      }}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase()) || (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    ></Select>
                  </Form.Item>
                  <Form.Item name="user_type" label="Source">
                    <Select
                      style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                      placeholder="Source"
                      className="transSelect"
                      id="activity_user_type"
                      onChange={sourceChange}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      options={[
                        {
                          label: 'All',
                          options: [
                            {
                              label: ' - - All Sources - - ',
                              value: 'all',
                            },
                          ],
                        },
                        {
                          label: 'Source',
                          options: userSourcesOptions,
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                  <Form.Item name="activity_type" label="Activity">
                    <Select
                      style={{ width: '100%', textAlign: 'left', background: 'transparent' }}
                      placeholder="Activity"
                      className="transSelect"
                      id="activity_type_filter"
                      onChange={activityChange}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      options={[
                        {
                          label: 'All',
                          options: [
                            {
                              label: ' - - All Activity - - ',
                              value: 'all',
                            },
                          ],
                        },
                        {
                          label: 'Activity',
                          options: userActivityTypesOptions,
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col xl={17} lg={17} md={12} sm={24} xs={24}>
              <Card className={`timeline-card box_shadow ${activityUserData.length <= 1 ? 'activity-parent-div' : ''} `}>
                <Spin spinning={loading}>
                  <div className="timeline_container">
                    <Timeline mode="left" pending={activityData.total > activityUserData.length} pendingDot={<div ref={loadMoreRef} style={{ height: '20px' }}></div>} items={timelineItems} />
                  </div>
                </Spin>
                {!loading && activityUserData.length < 1 && <Empty className="activity-center" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </main>
  );
}
