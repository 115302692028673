import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicRoutes from './routes/PublicRoutes';
import TexProtected from './routes/texProtected';
import BothRoutes from './routes/BothRoutes';
import PageNotFound from './components/pages/pageNotFound';
import { Button, Result } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { BlankPage } from './components/pages/BLANKPAGE';
import MainComp from './components';
import CompanyProfile from './components/pages/login/CompanyProfile';
import Login from './components/pages/login';
import { AppBlankPage } from './components/pages/BLANKPAGE/appBlankPage';
import Clients from './components/Clients';
import BankConnection from './components/pages/BankConnection';
import ClientBankConnection from './components/pages/details/clientTab/BankConnection';
import SubClient from './components/pages/details';
import Faqs from './components/pages/faqs';
import Property from './components/pages/property';
import AddEditProperty from './components/pages/property/viewEdit';
import PropertyDetails from './components/pages/property/details';
import RecordExpenses from './components/pages/record_expenses';
import EditRecordExpenses from './components/pages/record_expenses/edit';
import Reports from './components/pages/reports';
import SubUsers from './components/pages/tax-Dashboard/subUsers';
import UserOnbording from './components/pages/tax-Dashboard/userOnbording';
import Transactions from './components/pages/transactions';
import Video from './components/pages/videoComponent';
import ReviewRentalSummay from './components/pages/rental_summary/ReviewRentalSummay';
import UserActivity from './components/pages/UserActivity';
import Subscription from './components/pages/subscription';
import SubscriptionCheckout from './components/pages/subscription/checkout';
import DeleteAccount from './components/pages/delete-account';
import LoanOverview from './components/pages/reports/loan-overview';
import Dashboard from './components/pages/tax-Dashboard';
import Documentation from './components/pages/details/clientTab/Documentation';
import Document from './components/pages/details/clientTab/Document';
import { UserTypes } from './components/common/userTypes';
import UnsubscribeEmail from './components/pages/unsubscribe-emails';
import AuditTrail from './components/pages/details/clientTab/AuditTrail';
import Details from './components/pages/details/Details';
import UserProfile from './components/pages/UserProfile/UserProfile';
import ClientDashboard from './components/pages/details/clientTab/ClientDashboard';
import MobileVerify from './components/pages/login/MobileVerify';
import Referral from './components/pages/login/Referral';
import TwoFactor from './components/pages/login/TowFactor';
import AuditOutput from './components/pages/details/clientTab/AuditTrail/AuditOutput';

export const App = () => {
  const onUpdateTransaction = () => {};
  const tstate = null;

  return (
    <ErrorBoundary
      fallback={
        <MainComp
          comp={
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
              className="error-boundary-css"
              extra={
                <Button onClick={() => (window.location.href = '/')} type="primary">
                  Back Home
                </Button>
              }
            />
          }
        />
      }
    >
      <Routes>
        <Route
          path="/login/:type/:token?"
          element={
            <PublicRoutes>
              <Login />
            </PublicRoutes>
          }
        />
        <Route path="/unsubscribe" element={<UnsubscribeEmail />} />
        <Route
          path="/mobile-verification"
          element={
            <BothRoutes>
              <MobileVerify />
            </BothRoutes>
          }
        />
        <Route
          path="/referral"
          element={
            <BothRoutes>
              <Referral />
            </BothRoutes>
          }
        />
        <Route
          path="/complete-profile"
          element={
            <BothRoutes>
              <CompanyProfile />
            </BothRoutes>
          }
        />
        <Route
          path="/2fa-setup"
          element={
            <BothRoutes>
              <TwoFactor />
            </BothRoutes>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <MainComp key_name="home" />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/user/settings"
          element={
            <ProtectedRoutes>
              <MainComp comp={<UserProfile />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/settings"
          element={
            <TexProtected>
              <MainComp comp={<UserProfile />} />
            </TexProtected>
          }
        />

        <Route
          path="/property"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Property />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Transactions />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/transaction/:id"
          element={
            <ProtectedRoutes>
              <MainComp comp={<EditRecordExpenses />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/video"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Video />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/bank"
          element={
            <ProtectedRoutes>
              <MainComp comp={<BankConnection />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Reports />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/faqs"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Faqs />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/user-activity"
          element={
            <ProtectedRoutes>
              <MainComp comp={<UserActivity />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/rental-summary/:id"
          element={
            <BothRoutes>
              <MainComp comp={<ReviewRentalSummay />} />
            </BothRoutes>
          }
        />

        <Route
          path="/record-expenses"
          element={
            <BothRoutes>
              <MainComp comp={<RecordExpenses onUpdateTransaction={onUpdateTransaction} tstate={tstate} />} />
            </BothRoutes>
          }
        />
        <Route
          path="/delete-account"
          element={
            <BothRoutes>
              <MainComp comp={<DeleteAccount heading={true} />} />
            </BothRoutes>
          }
        />
        <Route
          path="/account/delete-account"
          element={
            <TexProtected>
              <MainComp comp={<DeleteAccount heading={true} />} />
            </TexProtected>
          }
        />
        <Route
          path="/property/:id"
          element={
            <BothRoutes>
              <MainComp comp={<PropertyDetails />} />
            </BothRoutes>
          }
        />
        <Route
          path="/property/edit/:id"
          element={
            <BothRoutes>
              <MainComp comp={<AddEditProperty />} />
            </BothRoutes>
          }
        />
        <Route
          path="/property/add"
          element={
            <BothRoutes>
              <MainComp comp={<AddEditProperty />} />
            </BothRoutes>
          }
        />

        <Route
          path="/delete-account"
          element={
            <ProtectedRoutes>
              <MainComp comp={<DeleteAccount />} />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/onboarding"
          element={
            <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.CHANNEL_PARTNER]}>
              <MainComp comp={<UserOnbording />} />
            </TexProtected>
          }
        />
        <Route
          path="/sub-users"
          element={
            <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.CHANNEL_PARTNER]}>
              <MainComp comp={<SubUsers />} />
            </TexProtected>
          }
        />
        <Route
          path="clients"
          element={
            <TexProtected role={[UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER]}>
              <MainComp comp={<Clients />} />
            </TexProtected>
          }
        />

        <Route
          path="clients/client"
          element={
            <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER]}>
              <MainComp comp={<SubClient />} />
            </TexProtected>
          }
        >
          <Route
            path="client-dashboard"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <ClientDashboard isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="reports"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <Reports autoRender={false} isTaxAccountant={true} />
              </TexProtected>
            }
          />

          <Route
            path="property/edit/:id"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <AddEditProperty isTaxAccountant={true} />
              </TexProtected>
            }
          />

          <Route
            path="audit-trail"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <AuditTrail />
              </TexProtected>
            }
          />
          <Route
            path="property/:id"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <PropertyDetails isTaxAccountant={true} />
              </TexProtected>
            }
          />

          <Route
            path="property/edit/:id"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <AddEditProperty isTaxAccountant={true} />
              </TexProtected>
            }
          />

          <Route
            path="transaction-details"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <Details isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="property/add"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <AddEditProperty isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="record-expenses"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <RecordExpenses isTaxAccountant={true} onUpdateTransaction={onUpdateTransaction} tstate={tstate} />
              </TexProtected>
            }
          />
          <Route
            path="property-details"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <Property isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="bank-connection"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <ClientBankConnection />
              </TexProtected>
            }
          />
          <Route
            path="documentation"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <Documentation isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="subscription"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <Subscription isTaxAccountant={true} />
              </TexProtected>
            }
          />
          <Route
            path="subscription/checkout"
            element={
              <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
                <SubscriptionCheckout isTaxAccountant={true} />
              </TexProtected>
            }
          />
        </Route>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Route
          path="mortgage-clients/client"
          element={
            <TexProtected role={[UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER]}>
              <MainComp comp={<SubClient />} />
            </TexProtected>
          }
        />
        <Route
          path="loan-overview"
          element={
            <TexProtected role={[UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER, UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
              <MainComp comp={<LoanOverview />} />
            </TexProtected>
          }
        />
        <Route
          path="/dashboard"
          element={
            <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.CHANNEL_PARTNER]}>
              <MainComp comp={<Dashboard />} />
            </TexProtected>
          }
        />
        <Route
          path="subscription"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Subscription />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="documentation"
          element={
            <ProtectedRoutes>
              <MainComp comp={<Documentation />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="audit-trail"
          element={
            <ProtectedRoutes>
              <MainComp comp={<AuditTrail />} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="document/:transaction_id/:user_id/:transaction_type/:doc_name"
          element={
            <TexProtected role={[UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT]}>
              <MainComp comp={<Document />} />
            </TexProtected>
          }
        />

        <Route
          path="subscription/:status"
          element={
            <BothRoutes>
              <MainComp comp={<Subscription />} />
            </BothRoutes>
          }
        />
        <Route
          path="subscription/checkout"
          element={
            <BothRoutes>
              <MainComp comp={<SubscriptionCheckout />} />
            </BothRoutes>
          }
        />

        <Route path="/bank-connected" element={<BlankPage />} />
        <Route path="/verify/user/app" element={<AppBlankPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};
