import { getRequest } from '../../../utils/apiUtils';
import { subscriptionDetailRequest, subscriptionDetailSuccess, subscriptionDetailFailure } from '../../reducers/slices/property/getSubscriptionDetails.slice';

export const GetSubscriptionDetails = (id) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/subscription-details`;
    } else {
      tempURl = `user/subscription-details?userId=${id}`;
    }
    await dispatch(subscriptionDetailRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(subscriptionDetailSuccess(result));
    }
    return await dispatch(subscriptionDetailFailure(result));
  };
};
