import { getRequest, postRequest } from '../../../utils/apiUtils';
import { getPropertyListRequest, getPropertyListSuccess, getPropertyListFailure } from '../../reducers/slices/subscription/getSubscribePropertyList.slice';

export const getSessionIdSubscription = async (param, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/subscription/purchase`;
  } else {
    tempURl = `user/subscription/purchase?userId=${id}`;
  }
  const { result } = await postRequest(tempURl, param);

  return result;
};

export const GetSubscribePropertyList = (id) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `user/subscription/properties`;
    } else {
      tempURl = `user/subscription/properties?userId=${id}`;
    }
    await dispatch(getPropertyListRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getPropertyListSuccess(result));
    }
    return await dispatch(getPropertyListFailure(result));
  };
};

export const CancelSubscription = (param, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/subscription/canceled`;
  } else {
    tempURl = `user/subscription/canceled?userId=${id}`;
  }
  return async () => {
    const result = await postRequest(tempURl, param);

    return result;
  };
};

export const GetInvoiceReceipt = async (id, userId) => {
  let tempURl = ``;
  if (!userId) {
    tempURl = `user/subscription/receipt/${id}`;
  } else {
    tempURl = `user/subscription/receipt/${id}?userId=${userId}`;
  }
  const result = await getRequest(tempURl);

  return result;
};
