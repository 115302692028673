import { CloudUploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Input, Popconfirm, Select, Table, notification } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { GetdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documetation.action';
import { useSelector, useDispatch } from 'react-redux';
import { GetReciptdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';
import { DocumentationReciptType, DocumentType, PropertyType, SettlementsType, UserStatus, UserTypes } from '../../../common/userTypes';
import dayjs from 'dayjs';
import './index.less';
import { DocumentTypeDropDown, getYearList } from '../../../../utils';
import UploadDocument from './UploadDocument';
import { GetPropertyListWithOutLoan } from '../../../../redux/actions/property/getPropertyListWithOutLoan.action';
import { deleteDocumentAsync } from '../../../../redux/reducers/slices/DocumentUpload/DocumentUploadSlice';
import CustomIcon from '../../../sidebar/custIcon';
import editIcon from '../../../../assets/images/edit.svg';
import deleteIcon from '../../../../assets/images/delete.svg';

const Documentation = ({ isTaxAccountant = false, propertyId = null, isReportPage = null }) => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [record, setRecord] = useState([]);

  // Added state variables for selected filters
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanReducer);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const currentClient = localStorage.getItem('currentClient');
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};
  const DocumentData = useSelector((state) => state.DocumentationDataReducer);

  useEffect(() => {
    dispatch(GetdocumentationdData(currentClient, propertyId));
    if (isReportPage === null) {
      dispatch(GetPropertyListWithOutLoan(currentClient));
    }
  }, [dispatch, currentClient, propertyId]);

  useEffect(() => {
    if (propertyDta && propertyDta.data.result && propertyDta.error === false) {
      let dt = propertyDta.data.result.map((elm, i) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' ' + elm.street_name : '') : '',
          flag: elm.property_type === PropertyType.COMMERCIAL_PROPERTY ? 1 : 0,
          key: i,
          property_type: elm.property_type,
        };
      });
      setPropertyData(dt);
    }
  }, [propertyDta]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const viewDocument = (record) => {
    let type;
    if ([SettlementsType.PURCHASE_SETTLEMENT, SettlementsType.SELL_SETTLEMENT].includes(record.documentType)) {
      type = DocumentationReciptType.SETTLEMENT;
    } else if (record.documentType === DocumentType.DEPRECIATION_DOC) {
      type = DocumentationReciptType.DEPRECIATION;
    } else if (record.documentType === DocumentType.EOFY_DOC) {
      type = 3;
    } else {
      type = DocumentationReciptType.PROPERTY_DOCUMENTS;
    }

    getBankDocument(record?.id, type);
  };

  const getBankDocument = (id, type) => {
    setLoading(true);
    dispatch(GetReciptdocumentationdData(id, currentClient, type)).then((res) => {
      if (res?.payload?.success) {
        setLoading(false);
        window.open(res?.payload?.result?.url, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const getDate = (item) => {
    if (item.status === UserStatus.PENDING) {
      return null;
    } else if ([DocumentType.DEPRECIATION_DOC, SettlementsType.PURCHASE_SETTLEMENT, SettlementsType.SELL_SETTLEMENT].includes(item.type)) {
      return item?.created_at;
    } else if (item?.type === DocumentType.EOFY_DOC) {
      return getYearList()?.find((yearItem) => yearItem?.value === item?.year)?.label;
    } else {
      return item?.document_date;
    }
  };

  const NestedData = DocumentData?.BankData?.result?.map((item) => {
    // Map fy_rent documents

    // Construct the Document array with the desired order
    const documents = {
      propertyName: `${item.street_number} ${item.street_name ? item.street_name + ' - ' + item.suburb + '-' + item.postcode : ''}`,
      created_at: item?.created_at,
      documentType: item?.type,
      doc_url: item?.doc_url,
      status: item?.status,
      propertyId: item?.property_id,
      display_type: item?.display_type,
      date: getDate(item),
      financial_year: getYearList()?.find((yearItem) => yearItem?.value === item?.year)?.label || null,
      documentName: item?.name || null,
      id: item?.id || null,
    };

    return {
      ...documents,
    };
  });

  const filteredData = useMemo(() => {
    return NestedData?.filter((item) => {
      const matchesSearch = item.propertyName.toLowerCase().includes(searchText.toLowerCase()) || item.documentName.toLowerCase().includes(searchText.toLowerCase());

      const matchesProperty = selectedProperty ? item.propertyId === selectedProperty : true;
      const matchesDocumentType = selectedDocumentType ? item.documentType === selectedDocumentType || item.display_type === selectedDocumentType : true;

      return matchesSearch && matchesProperty && matchesDocumentType;
    });
  }, [searchText, selectedProperty, selectedDocumentType, NestedData]);

  const deleteDocument = async (id) => {
    try {
      const res = await dispatch(deleteDocumentAsync(id));
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Success',
          description: 'Document deleted successfully!',
        });
        await dispatch(GetdocumentationdData(currentClient, propertyId));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const editDocument = (record) => {
    setEdit(true);
    setRecord(record);
    setOpen(true);
  };

  const columns = [
    {
      title: 'Document',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (text, record) => {
        return record?.id === null ? (
          <p className="browseBtn1">{text}</p>
        ) : (
          // eslint-disable-next-line
          <a className="browseBtn" onClick={() => viewDocument(record)}>
            {text}
          </a>
        );
      },
    },
    {
      title: 'Document Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        let display;
        if (record?.documentType === DocumentType.EOFY_DOC) {
          display = text;
        } else {
          display = text ? dayjs(text).format('DD-MM-YYYY') : '-';
        }
        return display;
      },
    },
    {
      title: 'Type',
      dataIndex: 'display_type',
      key: 'display_type',
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: (a, b) => {
    //     // Assign a numeric value based on the existence of 'id'
    //     const aValue = a.id ? 1 : 0;
    //     const bValue = b.id ? 1 : 0;
    //     return aValue - bValue;
    //   },
    //   render: (text, record) => {
    //     return record?.id ? (
    //       <span>
    //         <Badge status="success" className="me-2" />
    //         Completed
    //       </span>
    //     ) : (
    //       <span>
    //         <Badge status="warning" className="me-2" />
    //         Pending
    //       </span>
    //     );
    //   },
    // },
  ];

  if (!propertyId) {
    columns.unshift({
      title: 'Property',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: (text, record) => {
        return text || '-';
      },
    });
  }

  if (!isTaxAccountant) {
    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      width: '10%',
      render: (_, record) => {
        const displayAction = [DocumentType.EOFY_DOC, DocumentType.DEPRECIATION_DOC, SettlementsType.PURCHASE_SETTLEMENT, SettlementsType.SELL_SETTLEMENT].includes(record.documentType);

        return !displayAction ? (
          <div className="d-flex gap-3 ">
            <Button icon={<EyeOutlined />} onClick={() => viewDocument(record)} type="primary" size="large" />
            <Button icon={<CustomIcon icon={editIcon} />} onClick={() => editDocument(record)} type="primary" size="large" />

            <Popconfirm title="Are you sure you want to delete this document?" onConfirm={() => deleteDocument(record.id)} okText="Yes" cancelText="No">
              <Button icon={<CustomIcon icon={deleteIcon} />} type="primary" size="large" className="Delete_btn" />
            </Popconfirm>
          </div>
        ) : record?.id ? (
          <span className="text-center w-100">
            <Button icon={<EyeOutlined />} onClick={() => viewDocument(record)} type="primary" size="large" />
          </span>
        ) : (
          '-'
        );
      },
    });
  }

  const handleUpload = () => {
    setOpen(true);
  };

  const filterPropertyWise = (value) => {
    setSelectedProperty(value);
  };

  const filterDocumentWise = (value) => {
    setSelectedDocumentType(value);
  };

  return (
    <>
      <main className={user_type === UserTypes.USER && !propertyId ? 'page-content' : ''}>
        <div className={user_type === UserTypes.USER && !propertyId ? 'container-fluid bg-dash-back' : ''}>
          <div className={user_type === UserTypes.USER && !propertyId ? 'layout-specing' : ''}>
            <div className="d-flex align-items-center justify-content-between w-100 flex-row mt-2 custom-documentation-search ">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Documentation</h5>
              </div>
              <div className={`d-flex align-items-center gap-3 ${propertyId ? 'w-50' : 'w-75'}`}>
                {!propertyId && (
                  <Select
                    options={propertyData}
                    placeholder={<p className="text-center cursor-pointer m-0 p-0">- - All Properties - -</p>}
                    showSearch
                    optionFilterProp="children"
                    dropdownStyle={{
                      color: 'rgba(0, 0, 0, 0.45)',
                    }}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase()) || (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    className="w-100"
                    onChange={filterPropertyWise}
                    allowClear
                    value={selectedProperty}
                  />
                )}

                <Select
                  options={DocumentTypeDropDown(true)}
                  placeholder={<p className="text-center m-0 p-0">- - All Documents - -</p>}
                  className="w-100"
                  onChange={filterDocumentWise}
                  allowClear
                  value={selectedDocumentType}
                />

                <div className="custom-documentation-search-parent justify-content-start w-100">
                  <Input.Search
                    placeholder="Search Documentation"
                    allowClear
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="custom-documentation-search"
                    value={searchText}
                  />
                </div>
                {!isTaxAccountant && (
                  <Button icon={<CloudUploadOutlined />} size="large" type="primary" onClick={handleUpload}>
                    Upload Document
                  </Button>
                )}
              </div>
            </div>
            <div className="row align-items-center mt-4">
              <Table
                // locale={{
                //   triggerAsc: 'Sort by Pending Status',
                //   triggerDesc: 'Sort by Completed Status',
                //   cancelSort: 'Clear Sorting',
                // }}
                loading={DocumentData.isLoading || loading || propertyDta.isLoading}
                columns={columns}
                dataSource={filteredData}
                rowKey={'key'}
                bordered={false}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                }}
              />
            </div>
          </div>
        </div>
      </main>
      <UploadDocument isOpen={open} edit={edit} setEdit={setEdit} record={record} propertyId={propertyId} setRecord={setRecord} setIsOpen={setOpen} isReportPage={true} />
    </>
  );
};

export default Documentation;
