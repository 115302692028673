import React from 'react';
import CustomIcon from './custIcon';
import reportDashboard from '../../assets/images/Report.svg';
import onboarding from '../../assets/images/onbording.svg';
import Dashboard from '../../assets/images/Home.svg';
import { useSelector } from 'react-redux';
import { SettingOutlined } from '@ant-design/icons';

const SubTaxNavigation = () => {
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const isFinancialPlanner = notificationDta?.data?.result?.userData?.is_financial_planner;

  let TexNavigationConfig = [
    {
      title: 'Dashboard',
      path: `dashboard`,
      icon: <CustomIcon width={20} icon={Dashboard} />,
    },
    {
      title: 'Invite Clients',
      path: 'onboarding',
      icon: <CustomIcon width={20} icon={onboarding} />,
    },
  ];

  if (isFinancialPlanner) {
    TexNavigationConfig.push({
      title: 'Loan Overview',
      path: 'loan-overview',
      icon: <CustomIcon width={20} icon={reportDashboard} />,
    });
  }

  TexNavigationConfig.push({
    title: 'Settings',
    path: 'settings',
    key: 'menu_user_account',
    icon: <SettingOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
  });

  return TexNavigationConfig;
};

export default SubTaxNavigation;
