import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Button, Table } from 'antd';
import { CategoryType } from '../../../../common/userTypes';
import { useSelector, useDispatch } from 'react-redux';
import { amountFormat } from '../../../../../utils';
import { GetCategoryList } from '../../../../../redux/actions/category/getCategoryList.action';

const CategoryList = ({ category_list, auditYear, onClickCategoryValue, loading = false }) => {
  const dispatch = useDispatch();
  const categoryDta = useSelector((state) => state.getCategoryListReducer);

  useEffect(() => {
    if (!categoryDta.data?.result) {
      dispatch(GetCategoryList());
    }
  }, [dispatch]);

  const getCategoryName = (id) => {
    if (categoryDta.data.result) {
      const dt = categoryDta.data.result.find((e) => e.id === id);
      return dt.category_name || '';
    }
  };

  const categories = [CategoryType.LAND_TAX, CategoryType.COUNCIL_RATES, CategoryType.WATER_CHARGE, CategoryType.REPAIR_AND_MAINTENANCE, CategoryType.INSURANCE];
  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'property_name',
      key: 'property_name',
    },
    ...categories.flatMap((category, index) => [
      {
        title: getCategoryName(category),
        children: [
          {
            title: <span> {`FY ${auditYear.toString().slice(-2)}-${(Number(auditYear) + 1).toString().slice(-2)}`} </span>,
            dataIndex: `${category}_cy_amount`,
            key: `${category}_cy_amount`,
            align: 'right',
            render: (_text, record) => {
              return (
                <Button
                  type="text"
                  className="cursor-pointer"
                  onClick={() => onClickCategoryValue(record.key, category, auditYear)}
                  style={Number(record[`${category}_py_amount`]) > Number(record[`${category}_cy_amount`]) || Number(record[`${category}_cy_amount`]) === 0 ? { color: 'red' } : {}}
                >
                  {amountFormat(record[`${category}_cy_amount`] || 0)}
                </Button>
              );
            },
          },
          {
            title: <span> {`FY ${(Number(auditYear) - 1).toString().slice(-2)}-${auditYear.toString().slice(-2)}`} </span>,
            dataIndex: `${category}_py_amount`,
            align: 'right',
            key: `${category}_py_amount`,
            render: (text, record) => {
              return Number(text || 0) > 0 ? (
                <Button type="text" className="cursor-pointer" onClick={() => onClickCategoryValue(record.key, category, Number(auditYear) - 1)}>
                  {amountFormat(text || 0)}
                </Button>
              ) : (
                amountFormat(text || 0)
              );
            },
          },
        ],
      },
    ]),
  ];

  const transformedData = category_list.map((property) => {
    const row = {
      key: property.property_id,
      property_name: property.property_sell ? `${property.property_name} (Sold)` : property.property_name,
    };
    property.category.forEach((cat, index) => {
      row[`${cat.category_id}_cy_amount`] = cat.cy_amount;
      row[`${cat.category_id}_py_amount`] = cat.py_amount;
    });
    return row;
  });

  return <Table columns={columns} dataSource={transformedData} pagination={false} size="small" loading={loading} rowKey={() => crypto.randomUUID()} />;
};

CategoryList.propTypes = {
  category_list: PropTypes.array.isRequired,
  auditYear: PropTypes.string.isRequired,
  onClickCategoryValue: PropTypes.func.isRequired,
};

export default CategoryList;
