import React from 'react';
import { Descriptions, Drawer } from 'antd';
import Depreciation from './Depreciation';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';

const DrawerDepreciation = ({
  drawerOpen = false,
  setDrawerOpen = () => {},
  propertyId = null,
  onUpdateDepreciation = () => {},
  setDepreciationPropertyData = () => {},
  depreciationPropertyData = {},
  category_id = null,
}) => {
  const onClose = () => {
    setDrawerOpen(false);
    setDepreciationPropertyData(null);
  };

  return (
    <Drawer destroyOnClose title={`Depreciation`} placement="right" maskClosable={false} width={736} onClose={onClose} open={drawerOpen}>
      {depreciationPropertyData && (
        <Descriptions
          className="transmeta"
          column={1}
          bordered={false}
          items={[
            {
              key: '1',
              label: 'Property Name',
              children: `${depreciationPropertyData?.street_number ? depreciationPropertyData?.street_number + ' ' : ''} ${
                depreciationPropertyData?.street_name ? depreciationPropertyData?.street_name + ',' : ''
              } ${depreciationPropertyData?.suburb ? depreciationPropertyData?.suburb + ',' : ''} ${depreciationPropertyData?.state ? depreciationPropertyData?.state + ',' : ''} ${
                depreciationPropertyData?.postcode ? depreciationPropertyData?.postcode : ''
              }`,
            },
            {
              key: '3',
              label: 'Property Type',
              children: depreciationPropertyData?.property_type ? getPropertyTypeLabel(depreciationPropertyData?.property_type) : '',
            },
            {
              key: '2',
              label: 'Category',
              children: depreciationPropertyData?.category_name || '',
            },
          ]}
        ></Descriptions>
      )}
      <Depreciation propertyId={propertyId} onUpdateDepreciation={onUpdateDepreciation} category_id={category_id} fromReport={true} />
    </Drawer>
  );
};

export default DrawerDepreciation;
