import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { EyeFilled, PlusOutlined } from '@ant-design/icons';
import DrawerAddDepreciation from './DrawerAddDepreciation';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDepreciationListDataAsync, selectDepreciationListData, selectDepreciationLoading } from '../../../redux/reducers/slices/Depreciation/DepreciationSlice';
import { getDepreciationStatusLabel, getTransactionTypeLabel } from '../../../utils/ConstLabel';
import DrawerViewDepreciation from './DrawerVeiwDepreciation';
import dayjs from 'dayjs';
import { DepreciationStatus, UserTypes } from '../../common/userTypes';

const Depreciation = ({ propertyId = null, onUpdateDepreciation = () => {}, fromReport = false, category_id = null }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [record, setRecord] = useState(null);

  const currentClient = localStorage.getItem('currentClient');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const admin = JSON.parse(localStorage.getItem('admin'));

  const { user_type } = admin;

  const [filters, setFilters] = useState({});

  const depreciationData = useSelector(selectDepreciationListData);
  const loading = useSelector(selectDepreciationLoading);

  const { id } = useParams();

  // Fetch depreciation data when pagination changes
  useEffect(() => {
    const skip = (pagination.current - 1) * pagination.pageSize;
    const limit = pagination.pageSize;

    setFilters({ skip, limit, property_id: id || propertyId, user_id: currentClient });

    dispatch(
      getDepreciationListDataAsync({
        property_id: id || propertyId,
        skip,
        limit,
        user_id: currentClient,
        category_id: category_id,
      }),
    );
  }, [dispatch, id, pagination, propertyId, currentClient, category_id]);

  // Handle transaction from router state
  useEffect(() => {
    const transaction_id = location?.state?.transaction_id;
    if (!transaction_id) return;

    if (depreciationData?.data?.length > 0 && transaction_id) {
      const foundRecord = depreciationData.data.find((item) => item.id === transaction_id);
      if (foundRecord) {
        handleAddDepreciation(foundRecord);

        let path = '';
        if (user_type === UserTypes.USER) {
          path = `/property/${id}?tab=depreciation`;
        } else {
          path = `/clients/client/property/${id}?tab=depreciation`;
        }

        navigate(path, {
          state: { ...location.state, transaction_id: null },
          replace: true,
        });
      }
    }
  }, [location, depreciationData, id, navigate, user_type]);

  const handleAddDepreciation = (record) => {
    setRecord(record);
    setAddOpen(true);
  };

  const handleViewDepreciation = (record) => {
    setRecord(record);
    setViewOpen(true);
  };

  const columns = [
    {
      title: 'Transaction Source',
      dataIndex: 'transaction_type',
      render: (text) => getTransactionTypeLabel(text),
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      render: (text) => (text ? dayjs(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'depreciation_status',
      render: (text) => getDepreciationStatusLabel(text),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '5%',
      render: (text, record) => {
        let actionButton;
        if ([DepreciationStatus.DEPRECIATION_PENDING, DepreciationStatus.DEPRECIATION_NOT_APPLICABLE].includes(record.depreciation_status)) {
          actionButton = (
            <Button type="primary" className="w-100" onClick={() => handleAddDepreciation(record)} icon={<PlusOutlined />}>
              Add
            </Button>
          );
        } else if (record.depreciation_status === DepreciationStatus.DEPRECIATION_COMPLETED) {
          actionButton = (
            <Button type="primary" onClick={() => handleViewDepreciation(record)} icon={<EyeFilled />}>
              View
            </Button>
          );
        } else {
          actionButton = <>-</>;
        }
        return actionButton;
      },
    },
  ];

  if (!fromReport) {
    columns.unshift({
      title: 'Category',
      dataIndex: 'category_name',
    });
  }

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  return (
    <>
      <div className="container-fluid mt-3 d-flex flex-column gap-3 p-0">
        {!propertyId && (
          <div className="m-0 p-0">
            <h5 className="m-0">Depreciation</h5>
          </div>
        )}
        <div>
          <Table
            className="depreciationTable"
            loading={loading}
            columns={columns}
            dataSource={depreciationData?.data || []}
            pagination={{
              ...pagination,
              total: depreciationData?.total,
            }}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
          />
        </div>
      </div>

      {/* Drawers */}
      <DrawerAddDepreciation
        record={record}
        drawerOpen={addOpen}
        filters={filters}
        setDrawerOpen={setAddOpen}
        onUpdateDepreciation={onUpdateDepreciation}
        fromReport={fromReport}
        category_id={category_id}
      />
      <DrawerViewDepreciation
        record={record}
        drawerOpen={viewOpen}
        setDrawerOpen={setViewOpen}
        onUpdateDepreciation={onUpdateDepreciation}
        filters={filters}
        fromReport={fromReport}
        category_id={category_id}
      />
    </>
  );
};

export default Depreciation;
