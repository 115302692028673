import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteDepreciation,
  getDepreciationData,
  getDepreciationListData,
  getDepreciationProductList,
  getViewDepreciationListData,
  notApplicableDepreciation,
  saveDepreciationData,
} from '../../../actions/Depreciation/DepreciationApi';

const initialState = {
  error: false,
  isLoading: false,
  productList: [],
  listData: [],
  viewData: [],
  data: [],
};

export const getDepreciationProductListAsync = createAsyncThunk('depreciation/getDepreciationProducts', async (id) => {
  const response = await getDepreciationProductList(id);
  return response;
});

export const getDepreciationListDataAsync = createAsyncThunk('depreciation/getDepreciationListData', async (obj) => {
  const response = await getDepreciationListData(obj);
  return response;
});

export const getViewDepreciationListDataAsync = createAsyncThunk('depreciation/getViewDepreciationListData', async ({ id, currentClient }) => {
  const response = await getViewDepreciationListData(id, currentClient);
  return response;
});

export const getDepreciationDataAsync = createAsyncThunk('depreciation/getDepreciationData', async ({ id, type, user_id }) => {
  const response = await getDepreciationData(id, type, user_id);
  return response;
});

export const saveDepreciationDataAsync = createAsyncThunk('depreciation/saveDepreciation', async (body) => {
  const response = await saveDepreciationData(body);
  return response;
});

export const notApplicableDepreciationAsync = createAsyncThunk('depreciation/notApplicableDepreciation', async ({ id, currentClient }) => {
  const response = await notApplicableDepreciation(id, currentClient);
  return response;
});

export const deleteDepreciationAsync = createAsyncThunk('depreciation/deleteDepreciation', async ({ id, currentClient }) => {
  const response = await deleteDepreciation(id, currentClient);
  return response;
});

export const DepreciationSlice = createSlice({
  name: 'Depreciation',
  initialState,
  reducers: {
    clearDepreciationData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepreciationProductListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepreciationProductListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productList = action.payload.data;
      })
      .addCase(getDepreciationProductListAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getDepreciationListDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepreciationListDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listData = action.payload.data;
      })
      .addCase(getDepreciationListDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getDepreciationDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepreciationDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getDepreciationDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getViewDepreciationListDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getViewDepreciationListDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.viewData = action.payload.data;
      })
      .addCase(getViewDepreciationListDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(saveDepreciationDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveDepreciationDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveDepreciationDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(notApplicableDepreciationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(notApplicableDepreciationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(notApplicableDepreciationAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteDepreciationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepreciationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteDepreciationAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { clearDepreciationData } = DepreciationSlice.actions;

export const selectDepreciationLoading = (state) => state.depreciation.isLoading;
export const selectDepreciationListData = (state) => state.depreciation.listData;
export const selectDepreciationData = (state) => state.depreciation.data;
export const selectDepreciationProductList = (state) => state.depreciation.productList;
export const selectViewDepreciationListData = (state) => state.depreciation.viewData;

export default DepreciationSlice.reducer;
