import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Badge, Button, Card, Col, Empty, Input, List, Modal, notification, Row, Segmented, Skeleton, Spin, Tag, Typography } from 'antd';
import '../../../assets/less/index.less';
import { useDispatch, useSelector } from 'react-redux';
import { GetPropertyList } from '../../../redux/actions/property/getPropertyList.action';
import { useNavigate } from 'react-router-dom';
import { AppstoreOutlined, BarsOutlined, ExclamationCircleOutlined, NotificationOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteProperty } from '../../../redux/actions/property/deleteProperty.action';
import { UserTypes, DepreciationMethod, PropertyType } from '../../common/userTypes';
import PropertyNotificationModal from './notificationModal';
import { getPropertyTypeLabel } from '../../../utils/ConstLabel';
import CustomIcon from '../../sidebar/custIcon';
import editIcon from '../../../assets/images/edit.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import DT from '../../common/DT';
import propertyDashboard from '../../../assets/images/property.svg';
import { ReactComponent as Priminum } from '../../../assets/images/priminum.svg';
import { GetPropertyById } from '../../../redux/actions/property/getPropertyList.action';
import { formatCurrency } from '../../../utils';
import { clearPropertyData } from '../../../redux/reducers/slices/property/getPropertyListById.slice';

const { Paragraph } = Typography;

export default function Property(props) {
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);
  const currentClient = localStorage.getItem('currentClient');
  const [modal, contextHolder] = Modal.useModal();
  const [layoutView, setLayoutView] = useState('card');
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentNotification, setCurrentNotification] = useState([]);
  const [data, setData] = useState([]);
  const propertyData = useSelector((state) => state.getPropertyListReducer);
  const propertyDataLoading = propertyData.isLoading;

  //* API Call
  useEffect(() => {
    setLoading(propertyData.isLoading);
    if (propertyData && propertyData.data.result && propertyData.error === false) {
      let dt = propertyData.data.result.map((elm) => {
        return {
          ...elm,
          key: crypto.randomUUID(),
          bal: 'Not Available',
          value: elm.value || '-',
        };
      });
      setData(dt);
    }
  }, [propertyData]);

  const fetchPropertyList = useCallback(() => {
    dispatch(GetPropertyList(currentClient));
  }, [dispatch, currentClient]);

  useEffect(() => {
    fetchPropertyList();
  }, [fetchPropertyList]);

  const DeleteHandleProperty = (id) => {
    setLoading(true);
    dispatch(DeleteProperty(id, currentClient)).then((data) => {
      if (data.payload.success === true) {
        dispatch(GetPropertyList(currentClient));
        notification.success({
          message: 'Success',
          description: data.payload.message || 'Property Deleted Successfully',
        });
        setLoading(false);
      }
    });
  };

  const getDepreciationMethod = (key) => {
    return key === DepreciationMethod.PRIME_COST_DEPRECIATION ? 'Prime Cost' : 'Diminishing Value';
  };

  const onDeleteHandle = (record) => {
    modal.confirm({
      title: 'Are you sure you want to delete your property?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <div className="desc">
          {record.property_type !== PropertyType.OWNER_OCCUPIED_PROPERTY && record?.stripe_subscription_id ? (
            <Paragraph className="pt-2">Deleting this property will cancel your subscription to this property and permanently delete all property related data and documents.</Paragraph>
          ) : (
            <Paragraph className="pt-2">Deleting this property will delete all property related data and documents.</Paragraph>
          )}
          <Paragraph>We cannot recover or reinstate any deleted information if you change your mind later.</Paragraph>
          <Paragraph>Are you sure you want to proceed with the deletion?</Paragraph>
        </div>
      ),
      onOk: () => {
        DeleteHandleProperty(record.id);
      },
      width: 800,
    });
  };

  const columns = [
    {
      title: 'Property Address',
      dataIndex: 'street_number',
      key: 'street_number',
      width: '18%',
      render: (_text, record) => {
        return (
          <span
            className="cursor-pointer text-decoration-underline"
            onClick={() => {
              props.isTaxAccountant === true
                ? navigate('/clients/client/property/' + record.id, { state: { fromSameSite: true } })
                : navigate('/property/' + record.id, { state: { fromSameSite: true } });
            }}
          >
            {`${record.street_number}${record.street_name ? ' ' + record.street_name : ''}`}
          </span>
        );
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      width: '14%',
      render: (text) => {
        return getPropertyTypeLabel(text);
      },
    },
    {
      title: 'Net Income',
      dataIndex: 'netincome',
      key: 'netincome',
      width: '10%',
      render: (text) => {
        return formatCurrency(text);
      },
    },
    {
      title: 'Estimated Market Value',
      dataIndex: 'market_value',
      key: 'market_value',
      width: '13%',
      render: (text, record) => {
        const value2 = record?.admin_market_value;
        return text === 'SOLD' ? 'SOLD' : parseFloat(text) > value2 ? formatCurrency(text) : formatCurrency(value2);
      },
    },
    {
      title: 'Loan',
      dataIndex: 'loan',
      key: 'loan',
      width: '8%',
      render: (text) => {
        return `${text.toString() === 'Not Available' ? 'Not Available' : formatCurrency(text)}`;
      },
    },
    {
      title: 'LVR',
      dataIndex: 'lvr',
      key: 'lvr',
      width: '5%',
      render: (text) => {
        return `${text || text === '' ? text + '%' : 'Not Available'}`;
      },
    },
    {
      title: 'Depreciation Method',
      dataIndex: 'depreciation_method',
      key: 'depreciation_method',
      width: '10%',
      render: (_text, record) => {
        return `${record.depreciation_method ? getDepreciationMethod(record.depreciation_method) : ' - '}`;
      },
    },
    {
      title: 'Pending Tasks',
      dataIndex: 'action_item',
      key: 'action_item',
      align: 'center',
      render: (text, record) => {
        return (
          <Badge count={text && text.filter((item) => item.complete !== true).length} style={{ boxShadow: 'none' }} className="noti-badge ">
            <NotificationOutlined className="icon" onClick={() => setCurrentNotification(record)} />
          </Badge>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (_text, record) => {
        return (
          <Row gutter={7}>
            <Col>
              <Button
                type="primary"
                icon={<CustomIcon icon={editIcon} />}
                onClick={() => {
                  props.isTaxAccountant
                    ? navigate('/clients/client/property/edit/' + record.id, { state: { fromSameSite: true } })
                    : navigate('/property/edit/' + record.id, { state: { fromSameSite: true } });
                }}
                size="small"
              />
            </Col>
            <Col>
              <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" onClick={() => onDeleteHandle(record)} />
            </Col>
          </Row>
        );
      },
    },
  ];

  const clearNotifications = (isReload = false, id = null) => {
    setCurrentNotification([]);
    if (isReload) {
      dispatch(GetPropertyById(id, currentClient));
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const renderSkeleton = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <List.Item key={index}>
        <Card>
          <Skeleton active />
        </Card>
      </List.Item>
    ));
  };

  const renderPropertyList = (filteredData = []) => {
    return (
      <List
        className="mt-4"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={filteredData}
        pagination={
          filteredData.length > 10
            ? {
                pageSize: 10,
              }
            : false
        }
        locale={{
          emptyText: (
            <Card className="box_shadow">
              <Empty />
            </Card>
          ),
        }}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card className="property_list_card shadow-sm">
              <Skeleton loading={loading} active>
                <div className="row mb-3 border-bottom-1">
                  <div className="col d-flex justify-content-between">
                    <div className="card-title mb-0">
                      <div
                        className="d-flex gap-4 cursor-pointer"
                        onClick={() => {
                          dispatch(clearPropertyData());
                          props.isTaxAccountant === true
                            ? navigate('/clients/client/property/' + item.id, { state: { fromSameSite: true } })
                            : navigate('/property/' + item.id, { state: { fromSameSite: true } });
                        }}
                      >
                        <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                          <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={propertyDashboard} alt="" />
                        </Avatar>
                        <div>
                          <div className="d-flex mb-2 align-items-center justify-content-left">
                            <strong className="text-decoration-underline property_title">{`${item.street_number}${item.street_name ? ' ' + item.street_name : ''}`}</strong>
                            {item.stripe_subscription_id && <Priminum width={22} className="ml-1" title="Subscribed" />}
                          </div>
                          <strong>{`${item.suburb || ''}${item.state ? ', ' + item.state : ''}${item.postcode ? ' ' + item.postcode : ''}`}</strong>
                          <h6 className="card-subtitle text-secondary mt-2">{getPropertyTypeLabel(item.property_type)}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gap-2">
                      <Badge count={item.action_item && item.action_item.filter((item) => item.complete !== true).length} style={{ boxShadow: 'none' }} className="noti-badge mr-2 mt-1">
                        <NotificationOutlined className="icon" onClick={() => setCurrentNotification(item)} />
                      </Badge>
                      <Button
                        title="Edit"
                        type="primary"
                        icon={<CustomIcon icon={editIcon} />}
                        onClick={() => {
                          props.isTaxAccountant
                            ? navigate('/clients/client/property/edit/' + item.id, { state: { fromSameSite: true } })
                            : navigate('/property/edit/' + item.id, { state: { fromSameSite: true } });
                        }}
                        size="small"
                        className="card_button"
                      />

                      <Button title="Delete" type="primary" className="Delete_btn card_button" icon={<CustomIcon icon={deleteIcon} />} size="small" onClick={() => onDeleteHandle(item)} />
                    </div>
                  </div>
                </div>

                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 g-3">
                  {/* <div className="col">
                    <label>Type:</label> <strong>{getPropertyTypeLabel(item.property_type)}</strong>
                  </div> */}
                  <div className="col">
                    <label htmlFor="marketvalue" className="d-block">
                      Market Value
                    </label>
                    <strong>
                      {item.market_value === 'SOLD' ? (
                        <Tag color="red">SOLD</Tag>
                      ) : parseFloat(item.market_value) > item.admin_market_value ? (
                        formatCurrency(item.market_value)
                      ) : (
                        formatCurrency(item.admin_market_value)
                      )}
                    </strong>
                  </div>
                  <div className="col">
                    <label htmlFor="netincome" className="d-block">
                      Net Income
                    </label>{' '}
                    <strong> {formatCurrency(item.netincome)}</strong>
                  </div>
                  <div className="col">
                    <label htmlFor="loan" className="d-block">
                      Loan
                    </label>{' '}
                    <strong> {item.loan === 'Not Available' ? 'N/A' : formatCurrency(item.loan)}</strong>
                  </div>
                  <div className="col">
                    <label htmlFor="lvr" className="d-block">
                      LVR
                    </label>{' '}
                    <strong> {`${item.lvr || item.lvr === '' ? item.lvr + '%' : 'N/A'}`} </strong>
                  </div>
                  {/* <div className="col">
                    <label htmlFor="depreciation">Depreciation:</label> <strong> {item.depreciation_method ? getDepreciationMethod(item.depreciation_method) : 'N/A'} </strong>
                  </div> */}
                </div>
              </Skeleton>
            </Card>
          </List.Item>
        )}
      >
        {loading && renderSkeleton()}
      </List>
    );
  };

  const filteredData = data?.filter((item) => `${item.street_number} ${item.street_name} ${item.suburb} ${item.postcode} ${item.state}`.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <>
      {propertyDataLoading ? (
        <main className="page-content overflow-hidden pb-3">
          <div className="container-fluid bg-dash-back">
            <div className={`${props.isTaxAccountant ? '' : 'layout-specing'}`}>
              <div className="row align-items-center mt-1">
                <div className="col-12">
                  <div className="text-center align-items-center text-sm-start mb-sm-0 mb-3 mb-sm-0 d-flex">
                    <h5 className="m-0">Properties List</h5>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center ">
                  <Spin spinning size="large" />
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <>
          {admin1.user_type === UserTypes.USER ? (
            <main className="page-content">
              <div className="container-fluid bg-dash-back">
                <div className="layout-specing">
                  <div className="row align-items-center mt-1">
                    <div className="col-xl-4 col-md-4 col-sm-6 col-12">
                      <div className="text-center align-items-center text-sm-start mb-sm-0 mb-3 mb-sm-0 d-flex">
                        <h5 className="m-0">Properties List</h5>
                      </div>
                    </div>
                    <div className="col-xl-8 col-md-8 col-sm-6 col-12">
                      <div className="d-flex justify-content-end align-items-center">
                        <Input
                          placeholder="Search Property"
                          allowClear
                          onSearch={handleSearch}
                          onChange={(e) => handleSearch(e.target.value)}
                          size="small"
                          className="w-25 me-2"
                          value={searchText}
                          suffix={<SearchOutlined />}
                        />
                        <button onClick={() => navigate('/property/add', { state: { fromSameSite: true } })} className="property_btn1 btn btn-success p-0 px-3 py-2 me-2">
                          + Add Property
                        </button>
                        <Segmented
                          options={[
                            {
                              value: 'card',
                              label: 'Card',
                              icon: <AppstoreOutlined />,
                            },
                            {
                              value: 'list',
                              label: 'List',
                              icon: <BarsOutlined />,
                            },
                          ]}
                          size="large"
                          onChange={(value) => setLayoutView(value)}
                        />
                      </div>
                    </div>
                  </div>

                  {layoutView === 'card' && renderPropertyList(filteredData)}
                  {layoutView === 'list' && <DT paddingclassName="mt-4 property_list" columns={columns} data={filteredData} rowCount={10} />}
                </div>
              </div>
            </main>
          ) : (
            <div className="container-fluid bg-dash-back">
              <div className="">
                <div className="row align-items-center mt-1">
                  <div className="col-xl-3 col-md-3 col-sm-3 col-12">
                    <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                      <h5 className="m-0">Properties List</h5>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-9 col-sm-9 col-12">
                    <div className="d-flex justify-content-end align-items-center">
                      <Input
                        placeholder="Search Property"
                        allowClear
                        onSearch={handleSearch}
                        onChange={(e) => handleSearch(e.target.value)}
                        size="small"
                        className="w-25 me-2"
                        value={searchText}
                        suffix={<SearchOutlined />}
                      />

                      {!props.isTaxAccountant && (
                        <>
                          <button onClick={() => navigate('/record-expenses', { state: { fromSameSite: true } })} className="btn p-0 px-3 py-2 mr-1 property_btn">
                            + Add Expense
                          </button>

                          <button onClick={() => navigate('/property/add', { state: { fromSameSite: true } })} className="property_btn1 btn btn-success p-0 px-3 py-2 me-2">
                            + Add Property
                          </button>
                        </>
                      )}
                      <Segmented
                        options={[
                          {
                            value: 'card',
                            label: 'Card',
                            icon: <AppstoreOutlined />,
                          },
                          {
                            value: 'list',
                            label: 'List',
                            icon: <BarsOutlined />,
                          },
                        ]}
                        size="large"
                        onChange={(value) => setLayoutView(value)}
                      />
                    </div>
                  </div>
                </div>
                {layoutView === 'card' && renderPropertyList(filteredData)}
                {layoutView === 'list' && <DT paddingclassName="mt-4 property_list" columns={columns} data={filteredData} rowCount={10} />}
              </div>
            </div>
          )}
          <PropertyNotificationModal isTaxAccountant={props.isTaxAccountant} list={currentNotification?.action_item} clearNotifications={clearNotifications} loading={loading} />
          {/* Confirmatin dialog message of delete property */}
          {contextHolder}
        </>
      )}
    </>
  );
}
