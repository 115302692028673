import { Card, Drawer, List } from 'antd';
import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import DownloadStatementModal from '../BankConnection/DownloadStatementModal';
import Documentation from '../details/clientTab/Documentation';

const Download = ({ isTaxAccountant = null }) => {
  const [openDownloadStaement, setOpenDownloadStaement] = useState(false);
  const [openDownloadDocuments, setOpenDownloadDocuments] = useState(false);

  const handledownload = async (item) => {
    switch (item.value) {
      case 1:
        setOpenDownloadStaement(true);
        break;
      case 2:
        setOpenDownloadDocuments(true);
        break;
      default:
    }
  };

  const onCloseDownloadStaement = () => {
    setOpenDownloadStaement(false);
  };

  const onCloseDownloadDocuments = () => {
    setOpenDownloadDocuments(false);
  };

  return (
    <>
      <Card
        title={
          <div className="d-flex align-items-center gap-2">
            <span>Download Documents</span>
            {/* <Popover placement="bottom" title={tooltipText}>
              <InfoCircleOutlined className="cursor-pointer" />
            </Popover> */}
          </div>
        }
        className="box_shadow mt-3"
        headStyle={{ backgroundColor: '#f2f8f8' }}
      >
        <List
          // loading={loading}
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={[
            { label: 'Bank Statement', value: 1 },
            { label: 'Documents', value: 2 },
          ]}
          renderItem={(item) => (
            <List.Item className="cursor-pointer text-decoration-none" onClick={() => handledownload(item)}>
              <List.Item.Meta title={<h6>{item.label}</h6>} />
              <RightOutlined />
            </List.Item>
          )}
        />
      </Card>
      <DownloadStatementModal openDownloadStaement={openDownloadStaement} onCloseDownloadStaement={onCloseDownloadStaement} />

      <Drawer title="Download Documents" width={1120} destroyOnClose onClose={onCloseDownloadDocuments} open={openDownloadDocuments}>
        <Documentation isReportPage={true} isTaxAccountant={isTaxAccountant} />
      </Drawer>
    </>
  );
};

export default Download;
