import React from 'react';
import { Drawer } from 'antd';
import AddDepreciation from './AddDepreciation';
import { useDispatch } from 'react-redux';
import { clearDepreciationData } from '../../../redux/reducers/slices/Depreciation/DepreciationSlice';

const DrawerAddDepreciation = ({
  drawerOpen = false,
  setDrawerOpen = () => {},
  record = null,
  onUpdateDepreciation = () => {},
  filters = null,
  fromAuditTrail = false,
  category_id = null,
  auditYear = null,
}) => {
  const dispatch = useDispatch();

  const onClose = () => {
    setDrawerOpen(false);
    dispatch(clearDepreciationData());
  };

  return (
    <Drawer destroyOnClose title={`Depreciation`} placement="right" maskClosable={false} width={1120} onClose={onClose} open={drawerOpen}>
      <AddDepreciation
        filters={filters}
        record={record}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        onUpdateDepreciation={onUpdateDepreciation}
        fromAuditTrail={fromAuditTrail}
        category_id={category_id}
        auditYear={auditYear}
      />
    </Drawer>
  );
};

export default DrawerAddDepreciation;
