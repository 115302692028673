import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getPropertyNotificationDataSlice = createSlice({
  name: 'GetPropertyNotificationData',
  initialState,
  reducers: {
    getPropertyNotificationDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getPropertyNotificationDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getPropertyNotificationDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    removePropertyNotificationDepreciationItem: (state, action) => {
      const currentData = JSON?.parse?.(JSON?.stringify?.(state?.data));
      const items = currentData?.result || currentData?.result?.Data || [];
      if (items.length > 0) {
        const filteredItems = items?.find((item) => item.action_item[0].property_id === action?.payload?.propertyId);
        const filteredActionItems = filteredItems?.action_item?.filter?.((item) => item?.id !== action?.payload?.id);
        filteredItems.action_item = filteredActionItems;
        const newItems = items?.map?.((item) => (item?.propertyId === action?.payload?.propertyId ? filteredItems : item));
        state.data.result = newItems;
      }
    },
  },
});

export const { getPropertyNotificationDataSuccess, getPropertyNotificationDataFailure, getPropertyNotificationDataRequest, removePropertyNotificationDepreciationItem } =
  getPropertyNotificationDataSlice.actions;

export default getPropertyNotificationDataSlice.reducer;
